import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout/layout'
import { colors, mq } from '../styles/theme'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Form from '../components/Form/SignupForm'
import Rtf from '../components/Rtf/Article'
import Wave from './../components/Wave';


const Signup = ({ pageContext }) => {
  const {
    PageTitle,
    PageBenefitsCopy,
    PageBenefitsHeading,
    Image,
    PageSigninLink,
    PageSigninText
  } = pageContext
  const signUpImage = getImage(Image.localFile)

  return (
    <Layout>
      <FormWrapper>
        <FormBlock>
          <FormContainer>
            <FormHeading>{PageTitle}</FormHeading>
            <Form signInLink={PageSigninLink} signInText={PageSigninText.processed} />
          </FormContainer>
        </FormBlock>
        <BenefitsWrapper>
          <Wave/>
          <BenefitsContent>
            <BenefitsImageContainer>
              <GatsbyImage image={signUpImage} alt='' />
            </BenefitsImageContainer>
            <BenefitsTextWrapper>
              <BenefitsHeading>{PageBenefitsHeading}</BenefitsHeading>
              <BenefitsCopy copy={PageBenefitsCopy.processed} />
            </BenefitsTextWrapper>
          </BenefitsContent>
        </BenefitsWrapper>
      </FormWrapper>
    </Layout>
  )
}

export default Signup

export { Head } from '../components/_GatsbyPageHead'

const FormWrapper = styled.div`
  display: flex;
`

const FormBlock = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  min-block-size: 749px;
`

const FormContainer = styled.div`
  margin-inline: 20px;
  width: 100%;

  ${mq.desktop} {
    margin-inline: 160px;
  }
`

const FormHeading = styled.h2`
  font-family: "BuckeyeSerif";
  font-weight: 700;
  font-size: 28px;
  margin-block-end: 23px;
  text-align: center;

  ${mq.desktop} {
    font-size: 48px;
  }
`

const BenefitsWrapper = styled.div`
  background: ${colors.mainWaveColor};
  overflow: hidden;
  align-items: center;
  display: none;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  ${mq.desktop} {
    display: flex;
  }
`

const BenefitsContent = styled.div`
  margin-inline: 80px;
`

const BenefitsImageContainer = styled.div`
  margin-block-end: 45px;
  max-inline-size: 540px;
`

const BenefitsTextWrapper = styled.div`
  max-inline-size: 375px;
  margin: auto;
`

const BenefitsHeading = styled.h5`
  color: ${colors.white};
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 19px;
  margin-block-end: 12px;
`

const BenefitsCopy = styled(Rtf)`
  color: ${colors.white};
`
