import React from 'react'
import { useAlert } from '../../contextHooks/alerts'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { colors } from '../../styles/theme'
import successIcon from '../../assets/selected-check.svg'
import errorIcon from '../../assets/x-icon.svg'
import Rtf from '../Rtf/Article'

const ServerMessage = ({ messagetype, triggered, successText, errorMessage, useZustandContext }) => {
  const animateContainer = {
    height: '100%',
    width: '100%'
  };
  const animateContainerTransition = {
    width: {
      duration: 0.3
    },
    // eslint-disable-next-line quote-props
    default: {
      ease: 'linear'
    }
  };

  const animateTextInitial = {
    y: 10,
    opacity: 0
  }

  const animateText = {
    y: 0,
    opacity: 1
  };
  const animateTextTransition = {
    y: {
      duration: 0.6
    },
    // eslint-disable-next-line quote-props
    default: {
      ease: 'linear'
    }
  };

  const animateIcon = {
    opacity: 1
  }

  const animateIconTransition = {
    opacity: {
      duration: 0.6
    },
    // eslint-disable-next-line quote-props
    default: {
      ease: 'linear'
    }
  }

  const text = useAlert(state => state.text)
  const trigger = useAlert(state => state.triggered)
  const message = useAlert(state => state.messageType)
  const isContext = useZustandContext ? message : messagetype
  const errorText = errorMessage || 'Something went wrong';

  return (
    <MessageWrapper>
      <MessageContainer triggered={useZustandContext ? trigger : triggered} messagetype={useZustandContext ? message : messagetype} animate={(useZustandContext ? trigger : triggered) && animateContainer} transition={animateContainerTransition}>
        <MessageText messagetype={useZustandContext ? message : messagetype} initial={animateTextInitial} animate={(useZustandContext ? trigger : triggered) && animateText} transition={animateTextTransition}>
          {messagetype === 'error' ? errorText : <Rtf copy={useZustandContext ? text : successText}/>}
        </MessageText>
      </MessageContainer>
      <Icon animate={(useZustandContext ? trigger : triggered) && animateIcon} transition={animateIconTransition} src={isContext === 'error' ? errorIcon : successIcon} alt=''/>
    </MessageWrapper>
  )
}

export default ServerMessage

const MessageContainer = styled(motion.div)`
  display: flex;
  background-color: ${colors.white};
  border-left: 5px solid ${props => props.messagetype === 'error' ? colors.errorRed : colors.darkGreen};
  justify-content: space-between;
  height: 0;
  margin-block-end: ${props => props.triggered && '20px'};
  padding-inline: ${props => props.triggered && '25px'};
  padding-block: ${props => props.triggered && '13px'};
  width: 0;
`

const MessageWrapper = styled.div`
  position: relative;
`

const MessageText = styled(motion.p)`
  color: ${props => props.messagetype === 'error' ? colors.errorRed : colors.darkGreen};
  max-inline-size: 410px;
  margin-inline-end: 25px;
`

const Icon = styled(motion.img)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-25px, 13px);
  opacity: 0;
`
