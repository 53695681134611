import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormController from './FormController'
import { handleSignup } from '../../services/auth'
import Button from '../Button'
import ServerMessage from '../ServerMessage'
import Link from '../Link'
import styled from 'styled-components'
import { mq } from '../../styles/theme'
import Rtf from '../Rtf/Article'

function FormikWrapper({ signInLink, signInText }) {
  const [triggeredError, setTriggeredError] = useState(false)
  const initialValues = {
    name: '',
    email: '',
    password: ''
  }
  const validationSchema = Yup.object({
    name: Yup.string().required('Please enter your name.'),
    email: Yup.string().required('Email is a required field').email('Please enter a valid email address.'),
    password: Yup.string()
      .required('Please enter a password.')
      .min(8, 'Minimum of 8 characters required.')
      .test('valid', '3 out of 4 character sets required (Capital, Lowercase, Numbers, Special characters)', value => {
        let validConditions = 0;
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSpecialCharacter = /[!@#%&]/.test(value);

        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSpecialCharacter];
        conditions.forEach(condition =>
          condition ? validConditions++ : null
        );

        // Validate 3 out of 4 valid conditions
        if (validConditions >= 3) {
          return true;
        }
        return false;
      })
  })
  const onSubmit = values => {
    validationSchema
      .isValid(values)
      .then(async valid => {
        const res = await handleSignup(values.name, values.email, values.password)
        if (!res){
          setTriggeredError(true)
        } else {
          setTriggeredError(false)
          navigate('/');
        }
      });
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formik => (
        <Form method="post">
          {
            triggeredError &&  <ServerMessage messagetype='error' triggered={true} errorMessage='Email already exists, please log in or reset your password.'/>
          }
          <FormController
            control="input"
            type="text"
            label="First Name"
            name="name"
            error={ formik.errors.name }
          />
          <FormController
            control="input"
            type="email"
            label="Email"
            name="email"
            error={ formik.errors.email }
          />
          <FormController
            control="input"
            type="password"
            label="Password"
            name="password"
            onKeyUp={() => {
              formik.setErrors({ ...formik.errors, 'password': null })
              formik.setFieldTouched('password', false, false)
            }}
            error={ formik.errors.password }
          />
          <FormButton isSubmit text="Create an account" />

          <FormSigninLabel>
            <Rtf copy={signInText}/> <FormSigninLink type='primary' href='/app/login' text={signInLink} />
          </FormSigninLabel>
        </Form>
      )}
    </Formik>
  )
}
export default FormikWrapper

const FormSigninLabel = styled.div`
  display: flex;
  justify-content: center;
`

const FormSigninLink = styled(Link)`
  margin-inline-start: 5px;
`

const FormButton = styled(Button)`
  margin-block-end: 30px;
  width: 100%;

  ${mq.desktop} {
    margin-block-end: 50px;
  }
`
