import create from 'zustand'

export const useAlert = create(set => ({
  text: '',
  triggered: false,
  messageType: null,
  disableAlert: () => set(({
    text: '',
    triggered: false,
    messageType: null
  }))
}))
